/** @jsx jsx */
import { Box, Grid, jsx, Flex, Input, Label, Spinner } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useRef, useState } from "react"
import { useDispatchCurrentUser } from "./CurrentUser"
import { callApi } from "../utils"
import { navigate, Link } from "gatsby"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"

export default function LoginAlt() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const [errorMsg, setErrorMsg] = useState(null)
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  //this is to prevent double submits
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatchCurrentUser()

  const handleSubmit = async e => {
    e.preventDefault()
    setErrorMsg(null)
    setSubmitted(true)
    try {
      const response = await callApi("/auth/local", "POST", {
        identifier: emailRef.current.value,
        password: passwordRef.current.value,
      })

      if (!response.user) {
        throw "Cannot login.  Please try again"
      }
      setSubmitted(false)
      //there is a user
      dispatch({ type: "LOGIN", user: response.user })
      navigate("/app")
    } catch (err) {
      setSubmitted(false)
      setErrorMsg(err)
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  return (
    <div sx={styles}>
      <Box as="form" onSubmit={handleSubmit} className="loginForm">
        <fieldset id="login" sx={{ border: "none", py: 3 }}>
          <div>
            <Label htmlFor="email-address">Email</Label>
            <Input
              ref={emailRef}
              type="email"
              name="email-address"
              id="email-address"
            />
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <Box className="passwordContainer">
              <Input
                ref={passwordRef}
                type={passwordVisibility ? "text" : "password"}
                name="password"
                id="password"
              />
              {passwordVisibility ? (
                <AiFillEyeInvisible
                  onClick={togglePasswordVisibility}
                  className="passwordVisibilityButton"
                />
              ) : (
                <AiFillEye
                  onClick={togglePasswordVisibility}
                  className="passwordVisibilityButton"
                />
              )}
            </Box>

            <div>
              <Link to="/forgot-password" className="forgotLink">
                Forgot your password?
              </Link>
            </div>
          </div>
        </fieldset>
        <div className="buttonRow">
          {submitted && <Spinner />}
          <Input type="submit" value="Log in" disabled={submitted} />
        </div>
        <div>{errorMsg && <p className="error">{errorMsg}</p>}</div>
        <div className="otherLinks">
          <Link to="/sign-up">Sign up</Link>
          <Link to="/">Back to Homepage</Link>
        </div>
      </Box>
    </div>
  )
}

const styles = {
  "& .loginForm": {
    "& fieldset": {
      px: 0,
    },
    "& fieldset > div": {
      marginBottom: 3,
    },
    "input[type=submit]": {
      bg: "accent",
      color: "white",
      cursor: "pointer",
      transition: "background 0.3s ease-in-out",
      "&:hover:enabled": {
        background: darken("accent", 0.1),
      },
      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.3,
      },
    },
    "input[type=text]:focus-visible": {
      outline: "red",
    },
    a: {
      color: "accent",
    },
    "a.forgotLink": {
      float: "right",
      display: "block",
    },
    ".otherLinks": {
      margin: "0 auto",
      mt: 3,
      textAlign: "center",
      a: {
        pr: 3,
      },
    },
    ".passwordContainer": {
      position: "relative",
    },
    ".passwordVisibilityButton": {
      cursor: "pointer",
      fontSize: 4,
      position: "absolute",
      top: "11px",
      right: "10px",
    },
  },
  "& .buttonRow svg": {
    color: "accent",
    textAlign: "center",
    mx: "auto",
    display: "block",
    mb: 2,
  },
}
