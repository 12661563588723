/** @jsx jsx */
import { Box, Grid, jsx, Flex, Message } from "theme-ui"
import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import LoginAlt from "../components/Login-alt"
import LoginLogo from "../components/LoginLogo"
import HeaderBlockColor from "../components/headerBlockColor"
import { useCurrentUser } from "../components/CurrentUser"
import LoginLayout from "../components/loginLayout"
const apiURL = process.env.GATSBY_API_URL || "https://strapi-3k26.onrender.com"

const LoginPage = ({ location }) => {
  const { state: routeState } = location
  const redirect = !routeState
    ? "/app"
    : routeState.redirect === "app"
    ? "/app"
    : `/app/${routeState.redirect}`

  let currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      navigate("/app")
    }
    // console.log('currentUser',currentUser)
  }, [currentUser])

  //If email confirmation redirect
  const urlParams = new URLSearchParams(location.search)
  const status = urlParams.get("status")
  // console.log('status', status)
  return (
    <LoginLayout>
      <Box
        sx={{
          maxWidth: ["100%"],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div sx={{ minWidth: ["50%"] }}>
          <LoginLogo />

          <div
            sx={{
              background: "white",
              padding: 4,
              maxWidth: ["80vw", "50vw"],
              "& p.error": {
                color: "red",
              },
            }}
          >
            <h1>Login</h1>
            {status == "email_confirmation" && (
              <Message>
                Thank you for confirming your email. Login and complete your
                Masterclass profile before signing up for Masterclasses, Coffee
                with Creatives, Script Sessions, ticket offers and more!
              </Message>
            )}
            <p>Please use your credentials to login</p>
            <LoginAlt />
          </div>
        </div>
      </Box>
      <div sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <small>v 1.04</small>
      </div>
    </LoginLayout>
  )
}

export default LoginPage
